import { Controller } from "@hotwired/stimulus"
import smoothscroll from "smoothscroll-polyfill"
smoothscroll.polyfill()

export default class extends Controller {
  scroll() {
    const href = this.element.getAttribute("href")
    const id = href.replace(/^#/, '')
    const target = document.getElementById(id)

    if(target) {
      target.scrollIntoView({
        behavior: "smooth"
      });

      const mobileMenu = document.querySelector("#mobile-menu")
      mobileMenu.setAttribute("data-dropdown-open-value", false)
    } else {
      document.location.href = `/${href}`
    }
  }
}
