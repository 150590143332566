import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.loadEvent()
  }

  loadEvent() {
    const urlParams = new URLSearchParams(window.location.search)
    if(urlParams.get("special_event")) {
      const eventId = urlParams.get("special_event")
      if(document.getElementById(`event-${eventId}`)) {
        document.getElementById(`event-${eventId}`).click()
      }
    }
  }
}
