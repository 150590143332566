import { add, Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  connect() {
    if (this.checkboxTarget.id === "") {
      this.checkboxTarget.id = Math.random().toString(36)
    }
    let parentCheckBox = this.element.children[0].children[0].children[1]
    this.changeOnClick(parentCheckBox)
  }

  changeOnClick(element) {
    if(element.checked) {
      var changeEvent = new Event('change')
      element.dispatchEvent(changeEvent)
    }
  }

  handleChange(event) {
    let addressesElements = this.checkboxTarget.parentNode.closest("[data-controller]").nextElementSibling
    let uf = addressesElements.children[0].children[0]
    let city = addressesElements.children[1].children[0]
    if(event.target.checked) {
      this.checkboxTarget.disabled = false
      this.checkboxTarget.classList.remove("hidden")
      addressesElements.classList.add("hidden")
      uf.disabled = true
      city.disabled = true
      uf.value = null
      city.value = null
      city.innerHTML = "<option>Selecione uma cidade</option>"
    } else {
      this.checkboxTarget.value = null
      this.checkboxTarget.disabled = true
      this.checkboxTarget.classList.add("hidden")
      this.checkboxTarget.selectedIndex = 0
      addressesElements.classList.remove("hidden")
      uf.disabled = false
      uf.selectedIndex = 0
      city.selectedIndex = 0
      city.disabled = false
    }
  }
}
