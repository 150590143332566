import { Controller } from "@hotwired/stimulus"
import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';

export default class extends Controller {
  static targets = ["target"]
  static values = { kind: String }

  connect() {
    super.connect()
    this.loadSlider(this.kindValue)
  }

  loadSlider(kind) {
    if(kind === "glide-header") {
      this.mainHeaderSlider()
    } else if(kind === "glide-testimonial") {
      this.testimonial()
    }
  }

  mainHeaderSlider() {
    new Glide('.glide-header', {
      type: 'carousel',
      autoplay: 2000,
      perView: 4,
      // bound: true,
      breakpoints: {
        800: {
          perView: 2
        },
        400: {
          perView: 1
        }
      }
    }).mount()
  }

  testimonial() {
    new Glide('.glide-testimonial', {
      perView: 1,
      autoplay: 2000,
    }).mount()
  }
}
