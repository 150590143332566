import { Application } from "@hotwired/stimulus"
import "@hotwired/turbo-rails"
import { registerControllers } from "stimulus-vite-helpers"
import { Dropdown, Modal, Tabs } from "tailwindcss-stimulus-components"
import "lazysizes"

// Start the Stimulus application.
const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }

// Controller files must be named *_controller.js.
const controllers = import.meta.globEager("./**/*_controller.js")
registerControllers(application, controllers)

application.register("dropdown", Dropdown)
application.register("modal", Modal)
application.register("tabs", Tabs)
