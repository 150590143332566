import Flatpickr from 'stimulus-flatpickr'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import "flatpickr/dist/flatpickr.css"

export default class extends Flatpickr {
  initialize() {
    this.config = {
      locale: Portuguese,
      // mode: 'range',
      // static: true,
      // monthSelectorType: 'static',
      dateFormat: 'd/m/Y',
      // defaultDate: [new Date(), "2022-03-01"],
      // defaultDate: 'today',
      prevArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }
  }

  ready(selectedDates, dateStr, instance) {
    if(instance.config.mode == "range") {
      if(dateStr.includes('até')) {
        instance.element.value = dateStr.replace('até', '-')
      } else {
        instance.element.value = `${dateStr} - ${dateStr}`
      }
    }
    const customClass = instance.element.getAttribute('data-class')
    instance.calendarContainer.classList.add(customClass)
  }

  change(selectedDates, dateStr, instance) {
    if(instance.config.mode == "range") {
      instance.element.value = dateStr.replace('até', '-')
    }
    // if(instance.element.value.includes(' - ')) {
      // const form = instance.element.closest('form')
      // form.submit()
    // }
  }
}