import TomSelect from 'tom-select'
import 'tom-select/dist/css/tom-select.css';
import '~/stylesheets/tom-select.css';
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["target"]
  static values = { kind: String }

  connect() {
    super.connect()
    this.loadTomSelect(this.kindValue)
  }

  loadTomSelect(kind) {
    if(kind === "simple") {
      this.tomSelectSimple()
    } else if(kind === "multiple") {
      this.tomSelectMultiple()
    } else if(kind === "autocomplete") {
      this.tomSelectAutocomplete()
    }
  }

  tomSelectSimple() {
    var tomSelects = document.querySelectorAll('select.tom-select-simple')
    tomSelects.forEach(function(select) {
      new TomSelect(select)
    });
  }

  tomSelectMultiple() {
    var tomSelects = document.querySelectorAll('select.tom-select-multiple')
    tomSelects.forEach(function(select) {
      new TomSelect(select, {
        closeAfterSelect: false,
      })
    });
  }
}
