import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["target", "template"]

  add(event) {
    event.preventDefault()  
    var date = new Date().valueOf()
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, date)
    this.targetTarget.insertAdjacentHTML("beforebegin", content)
    if(document.getElementById("member-" + date)) {
      var count = document.getElementsByClassName("nested-form-wrapper").length
      document.getElementById("member-" + date).innerHTML = "Dados do participante " + count
    }
  }

  remove(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-form-wrapper")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = "none"
  }
}
