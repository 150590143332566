import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  change(event) {
    this.appendOrRemoveNestedForm(event.target.value)
    // if(event.srcElement.dataset.kind == "raizes") {
    //   this.basePricingUpdate(event.target.value, event.srcElement.dataset)
    // }
    this.pricingCalculate(event.target.value)
  }

  appendOrRemoveNestedForm(quantity) {
    var elements = document.getElementsByClassName("nested-form-wrapper");
    var difference = quantity - elements.length
    if(difference > 0) {
      for (var i = 0; i < difference; i++) {
        document.getElementById("add-item").click()
      }
    } else {
      var reverseElements = Array.prototype.slice.call(elements).reverse()
      for (var i = Math.abs(difference) - 1; i >= 0; i--) {
        reverseElements[i].remove()
      }
    }
  }

  // basePricingUpdate(quantity, dataAttr) {
  //   if(!dataAttr.customPrice) {
  //     let currentDate = new Date(dataAttr.eventDate)
  //     let endOfPromo = new Date("2023-07-30")

  //     let batch_1 = "R$ 199,00"
  //     let batch_2 = "R$ 219,00"

  //     if (currentDate <= endOfPromo) {
  //       batch_1 = "R$ 179,00"
  //       batch_2 = "R$ 197,00"
  //     }

  //     if (quantity < 3) {
  //       document.getElementById("base-value").innerHTML = batch_2
  //     } else {
  //       document.getElementById("base-value").innerHTML = batch_1
  //     }
  //   }
  // }

  pricingCalculate(quantity) {
    var baseValue = parseFloat(document.getElementById("base-value").innerText.replace("R$ ", "").replace(",", "."));
    var totalValue = document.getElementById("total-value");
    totalValue.innerHTML = `R$ ${(quantity * baseValue).toFixed(2)}`.replace(".", ",")
  }
}
