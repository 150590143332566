import { Controller } from "@hotwired/stimulus"
import Cleave from "cleave.js"
import "cleave.js/dist/addons/cleave-phone.br.js"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    super.connect()
  }

  date(event) {
    event.preventDefault()
    let currentDate = new Date()
    currentDate = currentDate.toISOString().split('T')[0]

    new Cleave(this.element, {
      date: true,
      delimiter: "/",
      datePattern: ["d", "m", "Y"],
      dateMin: "1900-01-01",
      dateMax: currentDate,
    })
  }

  phone(event) {
    event.preventDefault()
    new Cleave(this.element, {
      // phone: true,
      // phoneRegionCode: "BR",
      numericOnly: true,
      delimiters: ["(", ") ", "-"],
      blocks: [0, 2, 5, 4]
    })
  }
}
