import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["select"]
  static values = {
    url: String,
    param: String,
  }

  connect() {
    if (this.selectTarget.id === "") {
      this.selectTarget.id = Math.random().toString(36)
    }
    
    if(!this.element.classList.contains('hidden')) {
      let parentSelect = this.element.children[0].children[0]
      let childSelect = this.element.children[1].children[0]
      this.changeOnLoad(parentSelect)

      let self = this
      setTimeout(function() {
        self.setSelected(childSelect)
      }, 1000)
    }
  }

  changeOnLoad(element) {
    var changeEvent = new Event('change')
    element.dispatchEvent(changeEvent)
  }

  setSelected(element) {
    element.value = element.getAttribute("data-selected-value")
  }

  loadData(event) {
    let params = new URLSearchParams()
    params.append(this.paramValue, event.target.selectedOptions[0].value)
    params.append("target", this.selectTarget.id)

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
    // fetch(`${this.urlValue}?${params}`, {
    //   headers: { accept: "text/vnd.turbo-stream.html" }
    // })
    // .then(response => response.text())
    // .then(html => this.selectTarget.innerHTML = html)
  }
}
